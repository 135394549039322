<template>
  <div>
    <ca-header
      heading="Beitrag Bearbeitung"
      :loading="loading"
    >
      <template #buttonSpace>
        <button
          id="newsRemove"
          type="button"
          class="btn btn-danger mr-2"
        >
          löschen
        </button>
        <b-popover
          target="newsRemove"
          placement="bottom"
          title="Sind Sie sich sicher?"
          triggers="click blur"
        >
          <p>
            Die News wird dadurch unwiderruflich gelöscht!
          </p>
          <button
            class="btn btn-danger"
            @click="remove"
          >
            Bestätigen
          </button>
        </b-popover>
        <button
          type="button"
          class="btn btn-success text-white"
          @click="save"
        >
          Speichern
        </button>
      </template>
    </ca-header>
    <div class="container-fluid px-4 pt-4">
      <news-form
        v-if="!loading"
        :news="news"
      />
    </div>
  </div>
</template>

<script>
import CaHeader from '@/components/Layout/Header'
import NewsForm from '@/components/Forms/NewsForm.vue'
export default {
  name: 'NewsEdit',
  $_veeValidate: {
    validator: 'new' // give me my own validator scope.
  },
  components: {
    NewsForm,
    CaHeader
  },
  data () {
    return {
      loading: false,
      error: null,
      news: null,
      savePending: false,
      saveError: null
    }
  },
  watch: {
    $route: {
      immediate: true,
      handler: 'fetchData'
    }
  },
  methods: {
    async save () {
      try {
        const valid = await this.$validator.validateAll()
        if (!valid || this.savePending) {
          return
        }
        this.savePending = true
        this.saveError = null
        await this.news.save()
        await this.$router.push({ name: 'NewsList' })
      } catch (error) {
        this.saveError = error
      } finally {
        this.savePending = false
      }
    },
    async remove () {
      await this.news.remove()
      await this.$router.push({ name: 'NewsList' })
    },
    async fetchData () {
      try {
        this.loading = true
        this.news = await this.$store.dispatch('news/get', this.$route.params.newsId)
      } catch (error) {
        console.error(error)
        this.error = error
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
    @import "@/assets/scss/_variables.scss";

.scroll-wrapper{
  max-height: 200px;
  overflow-y: scroll;

    &::-webkit-scrollbar-track
    {
      border-radius: 10px;
      background-color: white;
    }

    &::-webkit-scrollbar
    {
      width: 12px;
      border-radius: 10px;
      background-color: white;
    }

    &::-webkit-scrollbar-thumb
    {
      border-radius: 10px;
      background-color: #A0A1A2;
    }

  .user-enter-active, .user-leave-active {
    transition: all 300ms;
  }
  .user-enter, .user-leave-to {
    opacity: 0;
    transform: translatex(30px);
  }
}

</style>
